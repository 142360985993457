import React from "react";
import {useLocation } from 'react-router-dom';


// core components
import NavbarMain from "components/Navbars/Navbar.js";
import LandingPageHeader from "./LandingPageHeader.js";
import LandingHeaderText from "./HeaderText";
import AboutUsSection from "./AboutUsSection.js";
import ProblemsSolveSection from "./ProblemsSolveSection.js";
import StackHolderSection from "./StackHolders.js";
import ImagineWinningSection from "./ImagineWinningSection.js";
import MainFooter from "components/Footers/MainFooter.js";
import WhyCityGenSection from "./WhyCitygen.js";
import OurSupportersSection from "./OurSupporters.js";
import NewsSection from "./NewsSection.js";

function LandingPage() {

  const location = useLocation();

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
          elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }

    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  },[location],);
  
  return (
    <>
      <NavbarMain />
      <LandingPageHeader />
      { window.innerWidth<768 && <LandingHeaderText/>}
      <AboutUsSection />
      <ProblemsSolveSection/>
      <WhyCityGenSection/>
      <StackHolderSection/>
      <OurSupportersSection/>
      <NewsSection/>
      <ImagineWinningSection/>
      <MainFooter/>
    </>
  );
}

export default LandingPage;
