import React from "react";
import {useLocation } from 'react-router-dom';

// core components
import NavbarMain from "components/Navbars/Navbar.js";
import AboutusPageHeader from "./AboutusPageHeader.js";
import MissionVission from "./MissionVission.js";
import AboutusHeaderText from "./AboutusHeaderText";
import ContactUs from "./ContactUs.js";
import OurJourney from "./OurJourney.js";
import TeamTowaso from "./TeamTowaso.js"
import PortfolioSection from "./OurPortfolio.js";
import MainFooter from "components/Footers/MainFooter.js";

function AboutUsPage() {

  const location = useLocation();

  if (location.hash) {
    let elem = document.getElementById(location.hash.slice(1))
    if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
    }
  } else {
  window.scrollTo({top:0,left:0, behavior: "smooth"})
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  },[location],);
    return (
      <>
        <NavbarMain />
        <AboutusPageHeader />
      { window.innerWidth<768 && <AboutusHeaderText/>}
        <MissionVission/>
        <OurJourney/>
        <PortfolioSection/>
        <TeamTowaso/>
        <ContactUs/>
        <MainFooter />
      </>
    );
  }
  
  export default AboutUsPage;