import React, { useState } from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
// core components
import "./about-us.scss";


function OurJourney() {

  const [selectedYear, setSelectedYear] = useState(2016);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setSelectedYear(prevCount => prevCount===2020? 2016 : prevCount + 1); 
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="bg-dark">
        <div className="problem-solve-section" 
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),url(" + require("assets/img/towaso/journey-bg.png") + ")"}}
        >
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="10" md="10" lg="8" xl="8">
            <div style={{fontSize:"24px", color:"white",fontWeight:"400"}}>
                <span>Our </span>
                <span style={{fontWeight:"510",color:"#286700"}}>Jour</span>
                <span style={{fontWeight:"510",color:"#FEC325"}}>ney </span>
            </div>
            </Col>
        </Row>
        <Row>
        <Col md="4" lg="4">
        <div style={{
          position: "absolute", 
          zIndex:"1",
          top:"15px"
          }}>
          <img
            alt="..."
            src={require("../../assets/img/towaso/Line-vt.png")}
          />
        </div>
        <Row style={{marginTop:"40px"}}>
          <Col className="ml-auto mr-auto">
          <span>
            <img
            alt="..."
            src={require("../../assets/img/towaso/Line-hr.png")}
          />
          </span>
          <i className="fa fa-circle" aria-hidden="true"></i>
          <span tag="a" style={{
            fontSize:"55px", fontWeight:"700", marginLeft:"40px", cursor: "pointer"}}
            onClick={()=>{setSelectedYear(2016);}}
            className={selectedYear===2016?"color-white":""}
          >
          2016
          </span>
          </Col>
        </Row>
        <Row style={{marginTop:"40px"}}>
          <Col className="ml-auto mr-auto">
          <span>
            <img
            alt="..."
            src={require("../../assets/img/towaso/Line-hr.png")}
          />
          </span>
          <i className="fa fa-circle" aria-hidden="true"></i>
          <span style={{fontSize:"55px", fontWeight:"700", marginLeft:"40px", cursor: "pointer"}}
            tag="a"
            onClick={()=>{setSelectedYear(2017);}}
            className={selectedYear===2017?"color-white":""}
          >
          2017
          </span>
          </Col>
        </Row>
        <Row style={{marginTop:"40px"}}>
          <Col className="ml-auto mr-auto">
          <span>
            <img
            alt="..."
            src={require("../../assets/img/towaso/Line-hr.png")}
          />
          </span>
          <i className="fa fa-circle" aria-hidden="true"></i>
          <span style={{fontSize:"55px", fontWeight:"700", marginLeft:"40px", cursor: "pointer"}}
            tag="a"
            onClick={()=>{setSelectedYear(2018);}}
            className={selectedYear===2018?"color-white":""}
          >
          2018
          </span>
          </Col>
        </Row>
        <Row style={{marginTop:"40px"}}>
          <Col className="ml-auto mr-auto">
          <span>
            <img
            alt="..."
            src={require("../../assets/img/towaso/Line-hr.png")}
          />
          </span>
          <i className="fa fa-circle" aria-hidden="true"></i>
          <span style={{fontSize:"55px", fontWeight:"700", marginLeft:"40px",cursor: "pointer"}}
            tag="a"
            onClick={()=>{setSelectedYear(2019);}}
            className={selectedYear===2019?"color-white":""}
          >
          2019
          </span>
          </Col>
        </Row>
        <Row style={{marginTop:"40px"}}>
          <Col className="ml-auto mr-auto">
          <span>
            <img
            alt="..."
            src={require("../../assets/img/towaso/Line-hr.png")}
          />
          </span>
          <i className="fa fa-circle" aria-hidden="true"></i>
          <span style={{fontSize:"55px", fontWeight:"700", marginLeft:"40px", cursor: "pointer"}}
            tag="a"
            onClick={()=>{setSelectedYear(2020);}}
            className={selectedYear===2020?"color-white":""}
          >
          2020
          </span>
          </Col>
        </Row>
        </Col>
        {selectedYear===2016 &&<JourneyOne />}
        {selectedYear===2017 &&<JourneyTwo />}
        {selectedYear===2018 &&<JourneyThree />}
        {selectedYear===2019 &&<JourneyFour />}
        {selectedYear===2020 &&<JourneyFive />}
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

const JourneyOne = () => {
  return(
    <Col md="8" lg="8" style={{marginTop:"45px", paddingLeft:"40px"}}>
      <ul style={{fontSize:"36px", fontWeight:"700", color:"white"}}>
        <li style={{marginTop:"15px"}}>
        <span style={{color:"#FEC325"}}>Awarded First Pilot Project </span>of Door to Door Collection by Dhanbad Municipal Corporation.
        </li>
        <li style={{marginTop:"15px"}}>Won <span style={{color:"#FEC325"}}> Urban Sanitation Hurrah Award</span></li>
        <li style={{marginTop:"15px"}}>
          Conferred as <span style={{color:"#FEC325"}}> Best project Implementation Agency </span> by Dhanbad Municipal Corporation
        </li>
      </ul>
    </Col>
  );
}

const JourneyTwo = () => {
  return(
    <Col md="8" lg="8" style={{marginTop:"45px", paddingLeft:"40px"}}>
      <ul style={{fontSize:"36px", fontWeight:"700", color:"white"}}>
        <li style={{marginTop:"15px"}}>
        Selected as<span style={{color:"#FEC325"}}> ESRI Enterprise Partner </span>under its Startup Scheme.
        </li>
        <li style={{marginTop:"15px"}}>Won <span style={{color:"#FEC325"}}> Most Promising Startup Award </span> by Melting Pot 2020 Innovation Summit.</li>
        <li style={{marginTop:"15px"}}>
        Our Client, Chas Municipal Corporation  was conferred as <span style={{color:"#FEC325"}}> Number 1 in Jharkhand in Swachta Survekshan.</span>
        </li>
      </ul>
    </Col>
  );
}

const JourneyThree = () => {
  return(
    <Col md="8" lg="8" style={{marginTop:"45px", paddingLeft:"40px"}}>
      <ul style={{fontSize:"36px", fontWeight:"700", color:"white"}}>
        <li style={{marginTop:"15px"}}>
        Runner-up in <span style={{color:"#FEC325"}}> Jharkhand Startup Yatra</span>.
        </li>
        <li style={{marginTop:"15px"}}>Then DC Bokaro got awarded by Honorable PM for<span style={{color:"#FEC325"}}> Poshan app created by our team</span>.</li>
        <li style={{marginTop:"15px"}}>
        Chas Municipal Corporation was again conferred as <span style={{color:"#FEC325"}}> Number 1 in Jharkhand for the Second time in Swachta Survekshan</span>.
        </li>
      </ul>
    </Col>
  );
}

const JourneyFour = () => {
  return(
    <Col md="8" lg="8" style={{marginTop:"45px", paddingLeft:"40px"}}>
      <ul style={{fontSize:"36px", fontWeight:"700", color:"white"}}>
        <li style={{marginTop:"15px"}}>
        Got Featured in <span style={{color:"#FEC325"}}> Bill & Melinda Gates Foundation and Better India </span> for our Organic Low Bio Home Cleaner.
        </li>
        <li style={{marginTop:"15px"}}>Got Selected for a <span style={{color:"#FEC325"}}> 10 lac Grant by Atal Bihari Bajpaee Innovation Lab</span>, Govt. of Jharkhand.</li>
        <li style={{marginTop:"15px"}}>
        Chas Municipal Corporation was again conferred as <span style={{color:"#FEC325"}}> Number 1 in Jharkhand for the Second time in Swachta Survekshan</span>.
        </li>
      </ul>
    </Col>
  );
}

const JourneyFive = () => {
  return(
    <Col md="8" lg="8" style={{marginTop:"45px", paddingLeft:"40px"}}>
      <ul style={{fontSize:"36px", fontWeight:"700", color:"white"}}>
        <li style={{marginTop:"15px"}}>
        Selected and Incubated at <span style={{color:"#FEC325"}}> IIT Patna Incubation Centre </span>for our Patent Pending Hardware and Software Integrated Solution, CityGen.
        </li>
        <li style={{marginTop:"15px"}}>Selected for <span style={{color:"#FEC325"}}> 10 Lacs Grant in Aid by Startup Bihar</span>.</li>
        <li style={{marginTop:"15px"}}>
        JNAC and Jugsulai NP were <span style={{color:"#FEC325"}}> Number 1 and Number 2 in Jharkhand </span>in Swachta Survekshan 2019-20 and JNAC was <span style={{color:"#FEC325"}}> 13th Nationally</span>.
        </li>
      </ul>
    </Col>
  );
}

export default OurJourney;