import React from "react";
// reactstrap components
import {Container, Col, Row, Card } from "reactstrap";
import ReactCardFlip from "react-card-flip";
// core components
import "./about-us.scss";

const portFolioData = [
    {
        id:0,
        img:require("assets/img/towaso/plastic-road.png"),
        description:"Jharkhand’s Municipal First Plastic Road",
        pointers:[
            "Saved 3 Tons of Carbon-dioxide from Environment.",
            "Used 0.16 kg of Plastic Waste per m.sq. for building a Road of 7*450 m.sq."
        ]
    },
    {
        id:1,
        img:require("assets/img/towaso/jharkhand-innovation-green-low-cost-toilet-cleaner.jpg"),
        description:"Low Cost Bio-Cleaner from Fruit Waste",
        pointers:[
            "Reduces Harmful Chemicals From Sewerage therefore helps in proper degradation of Faecal Sludge.",
            "Beneficial for Aquatic Ecosystem and Completely Organic and Eco-Friendly."
        ]
    },
    {
        id:2,
        img:require("assets/img/towaso/composite.png"),
        description:"Low Cost Bio Composter",
        pointers:[
            "Reduced the Price of Bio Composter By 10X",
            "Increased the Operational efficiency by an IOT Enabled System"
        ]
    },
    {
        id:3,
        img:require("assets/img/towaso/consultancy.png"),
        description:"Swacha Bharat Mission Consultancy",
        pointers:[
            "Delivering Number 1 Municipality in the State of Jharkhand in Swachta Survekshan from 2016 till Present.",
            "In Swachta Survekshan  2020 both of our clients were awarded 1st Rank in respective Categories."
            
        ]

    },
]

function PortfolioSection() {
    let pageHeader = React.createRef();

    const handleMouseEnter = function (id) {
        if(id===0){
          setFlippedList([true,false,false,false]);
        }
        if(id===1){
          setFlippedList([false,true,false,false]);
        }
        if(id===2){
          setFlippedList([false,false,true,false]);
        }
        if(id===3){
            setFlippedList([false,false,false,true]);
        }
    }
    
    const handleMouseLeave = function(){
        setFlippedList([false,false,false,false]);
    }

    const[flippedList, setFlippedList] = React.useState([false,false,false]);

  return (
    <>
      <div className="">
        <div className="about-us-section"
        style={{
            backgroundColor:"#FFF1E4",
            //backgroundImage:"url(" + require("assets/img/towaso/news-section-bg.png") + ")"
        }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span style={{fontWeight:"510",color:""}}>Our</span>
            <span style={{fontWeight:"510",color:"#286700"}}> Port</span>
            <span style={{fontWeight:"510",color:"#FEC325"}}>folio</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"60px"}}>
        <Col>
        <Row>
            {portFolioData.map((data,key)=>{
                return(
                    <Col lg="6" key={key} style={{paddingLeft:"20px"}}>
                    <ReactCardFlip isFlipped={flippedList[data.id]} flipDirection="vertical">
                    <Card className="no-transition CardFront"
                    onMouseEnter={() => handleMouseEnter(data.id)} >
                    <div
                        style={{
                        backgroundImage:
                            "url(" + data.img + ")",
                        borderRadius:"12px 12px 0 0"
                        }}
                        className="page-header page-header-portfolio"
                        data-parallax={true}
                        ref={pageHeader}
                    >
                    </div>
                    <Row style={{padding:"10px 25px 10px 25px", height:"7vh"}}>
                        <Col>
                        <Row>
                        <Col className="text-center" style={{fontSize:"24px"}}>
                        {data.description} 
                        </Col>
                        </Row>
                        </Col>
                    </Row>
                    </Card>
                    <Card className="no-transition CardBack"
                    onMouseLeave={() => handleMouseLeave()}>
                        <Row style={{padding:"10px 25px 10px 25px", height:"30vh"}}>
                        <Col>
                        <Row>
                        <Col style={{fontSize:"24px"}}>
                            <ul style={{fontSize:"20px", marginTop:"20px"}}>
                                {data.pointers.map((point,key) => {
                                    return(
                                        <li key={key}>{point}</li>
                                    );
                                })}
                            </ul>
                        </Col>
                        </Row>
                        </Col>
                    </Row>
                    </Card>
                    </ReactCardFlip>
                    </Col>
                );
            })}
        </Row>
        </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default PortfolioSection;
