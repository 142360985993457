import React from "react";

// reactstrap components
import { Card, Container, Col, Row } from "reactstrap";
// core components
import "./landing.scss";

const featureDeatils = [
  {
    id:"1",
    logoPath:require("../../assets/img/towaso/city-mapping-green.svg"),
    featureHeading:"City Mapping",
    featureDescription:"City Mapping and Planning  through Unique Identity Code(UID) of Every Shop/Household in the City."
  },
  {
    id:"2",
    logoPath:require("assets/img/towaso/track-seg-green.svg"),
    featureHeading:"Tracking Segregation",
    featureDescription:"Source Segregation Tracking and Monitoring for each and every Household."
  },
  {
    id:"3",
    logoPath:require("assets/img/towaso/alarm-clock-green.svg"),
    featureHeading:"ETA of Your Safaiwala",
    featureDescription:"Pre-Alarm Notification before the arrival of Garbage Collector."
  },
  {
    id:"4",
    logoPath:require("assets/img/towaso/insight-green.svg"),
    featureHeading:"Insights & analytics",
    featureDescription:"Weekly / Monthly / Quarterly  AI Driven Analytics and Inferences for the Municipal Corporations for Urban City Planning and Designing."
  },
  {
    id:"5",
    logoPath:require("assets/img/towaso/ScrApp-green.svg"),
    featureHeading:"ScrApp",
    featureDescription:"Integrates all the Kabadiwallas and other Stakeholders directly to the Household and the Municipalities."
  },
  {
    id:"6",
    logoPath:require("assets/img/towaso/on-call-green.svg"),
    featureHeading:"On Call Service",
    featureDescription:"Request for Household Sanitation Services by Trusted Service Providers."
  },
  {
    id:"7",
    logoPath:require("assets/img/towaso/HRMS-green.svg"),
    featureHeading:"HRMS",
    featureDescription:"For the Municipality to Track their SWM Field Force."
  }
];

function WhyCityGenSection() {

  const [featureSelected, setFeatureSelected] = React.useState(0);

  return (
    <>
      <div id="features" className="">
        <div className="about-us-section" style={{
        backgroundColor:"#FFD051"
        //backgroundImage:
        //  "url(" + backgroundImageUrl + ")",
      }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#286700"}} />
            </svg>
        </div>
        <Container>
          <div style={{
            position: "absolute", 
            zIndex:"2",
            right:"70px",
            bottom:"10px",
            paddingTop:"5px"}}>
            <img
                alt="..."
                height="550px"
                src={require("../../assets/img/towaso/wastepickerhappy-01 1.png")}
              />
          </div>
          <Row>
          <Col sm="12" md="12" lg="7" xl="7">
          <div className="">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
              <span>Why </span>
              <span style={{fontWeight:"510",color:"green"}}>Citygen </span>
              <span style={{fontWeight:"400",color:"black"}}>: 7 revolutionary features</span>
              </div>
          </div>
          </Col>
          <Col sm="12" md="12" lg="5" xl="5">
          </Col>
          </Row>
          <Row style={{marginTop:"70px"}}>
            <Col lg="4" xl="4">
              <Row>
                <Col lg="12">
                  <Card className={featureSelected===0?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(0);}}
                  >
                    <Row>
                    <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===0?require("../../assets/img/towaso/city-mapping-green.svg"):require("../../assets/img/towaso/city-mapping.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center why-citigen-products" 
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===0?"color-green":""}>City Mapping</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Card className={featureSelected===1?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(1);}}
                  >
                    <Row>
                      <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===1?require("../../assets/img/towaso/track-seg-green.svg"):require("../../assets/img/towaso/track-seg.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===1?"color-green":""}>Track Segregation</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Card className={featureSelected===2?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(2);}}
                  >
                    <Row>
                      <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===2?require("../../assets/img/towaso/alarm-clock-green.svg"):require("../../assets/img/towaso/alarm-clock.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===2?"color-green":""}>ETA of SafaiMitra</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Card className={featureSelected===3?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(3);}}
                  >
                    <Row>
                      <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===3?require("../../assets/img/towaso/insight-green.svg"):require("../../assets/img/towaso/insight.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===3?"color-green":""}>Insights & analytics</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Card className={featureSelected===4?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(4);}}
                  >
                    <Row>
                      <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===4?require("../../assets/img/towaso/ScrApp-green.svg"):require("../../assets/img/towaso/ScrApp.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===4?"color-green":""}>ScrApp</span>
                      </Col>
                    </Row>
                  </Card> 
                </Col>
              </Row>
            </Col>
            <Col lg="8" xl="8">
              <Row>
                <Col lg="12">
                  <Card className="no-transition" 
                  style={{height:"380px", border:"2px solid rgb(40,103,0)"}}>
                    <Row style={{
                        marginLeft:"40px",
                        height:"100px"}}>
                      <Col style={{
                        marginTop:"40px"
                      }}>
                      <span>
                        <img 
                        src={featureDeatils[featureSelected].logoPath}
                        alt="..."
                        //className="filter-yellow"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                    </Row>
                    <Row style={{
                        marginLeft:"40px"}}>
                      <Col
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#286700"
                        }}>
                      <span>{featureDeatils[featureSelected].featureHeading}</span>
                      </Col>
                    </Row>
                    <Row style={{
                        marginLeft:"40px"}}> 
                      <Col lg="10" xl="10"
                      style={{ 
                        fontSize:"28px", 
                        fontWeight:"500",
                        color:"#252525"
                        }}>
                      <span>
                      {featureDeatils[featureSelected].featureDescription}
                      </span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card className={featureSelected===5?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(5);}}
                  >
                    <Row>
                    <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===5?require("../../assets/img/towaso/on-call-green.svg"):require("../../assets/img/towaso/on-call.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===5?"color-green":""}>On Call Service</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card className={featureSelected===6?"border-2px-green no-transition":"no-transition"}
                  tag="a"
                  style={{ 
                    cursor: "pointer", 
                    height:"80px",
                    fontFamily:"ProductSansRegular",
                    fontWeight:"500"
                  }}
                  onClick={()=>{setFeatureSelected(6);}}
                  >
                    <Row>
                      <Col lg="3" style={{
                        marginTop:"12px",
                        marginBottom:"12px"}}>
                      <span style={{marginLeft:"24px"}}>
                        <img 
                        src={featureSelected===6?require("../../assets/img/towaso/HRMS-green.svg"):require("../../assets/img/towaso/HRMS.svg")}
                        alt="..."
                        //className="filter-green"
                        style={{borderRadius:"0"}}
                        />
                        </span>
                      </Col>
                      <Col lg="9" className="align-self-center"
                      style={{
                        marginTop:"22px",
                        marginBottom:"22px", 
                        fontSize:"24px", 
                        fontWeight:"500",
                        color:"#808080"
                        }}>
                        <span className={featureSelected===6?"color-green":""}>HRMS</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{
            position: "absolute", 
            zIndex:"-1",
            left:"-60px",
            bottom:"-10px",
            paddingTop:"5px"}}>
            <img
                alt="..."
                height="250px"
                src={require("../../assets/img/towaso/waste-01 1.png")}
              />
          </div>
        </Container>
        </div>
      </div>
    </>
  );
}

export default WhyCityGenSection;
