import React from "react";

// reactstrap components
import { Container, Col, Row, Button, Modal } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
// core components
import BackgroundVideo from "components/BackgroundVideo/BackgroundVideo";
import emailjs from 'emailjs-com';
//import Swal from 'sweetalert2';
import "./landing.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  rootProgress:{
    display:"flex",
    '& > * + *': {
      marginLeft: theme.spacing(4),
    },
  }
}));

function ImagineWinningSection() {

  const classes = useStyles();

  const [senderEmail, setSenderEmail] = React.useState("");
  const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState("");

  const handleChange = (event) => {
    setSenderEmail(event.target.value);
  };

  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
    //onClickClear();
  };

  const addToContacts = () =>{
    //e.preventDefault();

    if(!senderEmail){
      setIsInvalidEmail(true);
      setErrorEmail("Please enter your email address.");
      return;
    }
    else if(senderEmail!=="undefined"){
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(senderEmail)) {
        setIsInvalidEmail(true);
        setErrorEmail("Please enter valid email address.")
        return;
      }
      else{
        setIsInvalidEmail(false);
      }
    }

    if(!senderEmail || isInvalidEmail){
      return;
    }
    const templateParams = {
      from_name: "New Subsciber (" + senderEmail + ")",
      reply_to: senderEmail,
      to_name:"Team Citygen",
      message: "A new user subscribed to TowaSo Newsletter. Email: " + senderEmail
    };

    emailjs.send("service_oc8qwfr", "newsletterSubscribe", templateParams, "user_hwy195IMkI5aIHBQZajdF").then((result) => {
      console.log(result.text);
      setSenderEmail("");
      // Swal.fire({
      //   title: 'Message Successfully Sent.',
      //   icon: 'success'
      // });
      toggleModal();
      }, (error) => {
        console.log(error.text);
        // Swal.fire({
        //   title: 'Failed to Send Message. Please try again later.',
        //   icon: 'error'
        // });
    });

  };

  return (
    <>
      <div className="">
        <div className="about-us-section" 
        style={{
          backgroundColor:"#262626"
        }}>
        <BackgroundVideo videoSource="https://citygen.ams3.digitaloceanspaces.com/Assets-Website/XiaoYing_Video_1611222971063_HD.mp4" opacity="0.4"/>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="12" className="ml-auto mr-auto text-center" style={{marginTop:"65vh"}}>
            <Button className="btn-round" color="warning" type="button" outline size="lg"
            onClick={()=>{toggleModal()}}>
            Join the Revolution
            </Button>
            </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal} style={{textAlign:"center"}}>
            <div className="modal-body">
            <Row>
                <Col className="text-center"> 
                <div style={{paddingTop:"10px",paddingBottom:"10px", fontSize:"22px"}}>Subscriber to Citygen Newsletter</div>
                <form className={classes.root} noValidate autoComplete="off" style={{width:"100%", marginTop:"20px", marginBottom:"20px"}}>
                <TextField 
                id="email"
                name="email" 
                label="Email Address" 
                variant="outlined" 
                style={{width:"100%", marginTop:"22px"}}
                onChange={(e) => {handleChange(e);}}
                value={senderEmail}
                error={isInvalidEmail}
                aria-describedby="email-error-text"
                />
                {isInvalidEmail && <FormHelperText id="email-error-text" style={{color:"red", width:"100%"}}>{errorEmail}</FormHelperText>}
                </form>
                </Col>
            </Row>
            <Row>
                <Col className="text-center" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                <Button
                className="btn-round mr-1"
                color="success"
                outline
                type="button"
                onClick={()=>{addToContacts()}}
                >
                Done
                </Button>
                </Col>
            </Row>
            </div>
          </Modal>
        </Container>
        </div>
      </div>
    </>
  );
}

export default ImagineWinningSection;
