import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

import "./navbar.scss";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function NavbarMain() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [prevScrollpos, setPrevScrollpos]= React.useState(window.pageYOffset);
  const [visible, setVisible] = React.useState(true);
  //const [navHidden, setNavHidden] = React.useState("navbar-visible");
  //const [isNavHidden, setIsNavHidden] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      /*
      let lastScrollTop = 0;
      const currentScrollTop = document.documentElement.scrollTop;
      if (!isNavHidden && currentScrollTop > lastScrollTop) {
        setNavHidden("navbar-hidden");
        setIsNavHidden(true);
      } else if(isNavHidden) {
        setNavHidden("navbar-visible");
        setIsNavHidden(false);
      }
      lastScrollTop = currentScrollTop;
      */
      if (
        document.documentElement.scrollTop > 49 ||
        document.body.scrollTop > 49
      ) {
        setNavbarColor("navbar-semi-transparent");
      } else if (
        document.documentElement.scrollTop < 50 ||
        document.body.scrollTop < 50
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    // Hide or show the menu.
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if(currentScrollPos < 50){
        return;
      }

      const visible = prevScrollpos > currentScrollPos;

      setPrevScrollpos(currentScrollPos);
      setVisible(visible)
    };

    window.addEventListener("scroll", updateNavbarColor);
    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      window.removeEventListener("scroll", handleScroll);

    };
  });

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor, {
        "navbar-hidden": !visible
      })}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/home"
            title="Citygen"
            tag={Link}
          >
          <img
              style={{height: "60px"}}
              alt="..."
              src={require("../../assets/img/towaso/Citygen-Logomark-White.png")}
          />
          <img
              style={{height: "45px"}}
              alt="..."
              src={require("../../assets/img/towaso/citygen-navbar-logo-white.png")}
          />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/home" tag={Link}>
                <span style={{fontSize:"16px",color:"#00802b"}}>Home</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about" tag={Link}>
                <span style={{fontSize:"16px",color:"#cbcbcb"}}>About Us</span> 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/home/#features" tag={Link}>
              <span style={{fontSize:"16px",color:"#EAAF00"}}>Our Solutions</span> 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about/#contact" tag={Link}>
              <span style={{fontSize:"16px",color:"#cbcbcb"}}>Contact</span> 
              </NavLink>
            </NavItem>
            {/*
            <NavItem>
              <NavLink to="/portfolio" tag={Link}>
                <span style={{fontSize:"16px",color:"white"}}>Portfolio</span> 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/payment" tag={Link}>
                <span style={{fontSize:"16px",color:"white"}}>Payment</span> 
              </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarMain; 