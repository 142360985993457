import React from "react";

// reactstrap components
import {
    Card,
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";
  
// core components
import "./landing.scss";

const items = [
    {
      src: require("assets/img/towaso/nita_sinha.jpg"),
      altText: "Somewhere",
      caption: "Somewhere",
      name:"Neeta Sinha",
      about:"Child Rights Activist, India",
      testimonial:"Their awareness campaign is the best. They aim at very good cause of sustainable environment, I wish them success."
    },
    {
      src: require("assets/img/towaso/tran.jpg"),
      altText: "Somewhere else",
      caption: "Somewhere else",
      name:"Tran Tram",
      about:"Intern, Vietnam",
      testimonial:"I am truly thankful to the team for providing me a working experiences in India. The Working culture at here is innovative and of global standards."
    },
    {
      src: require("assets/img/towaso/carla.jpg"),
      altText: "Here it is",
      caption: "Here it is",
      name:"Carla En",
      about:"Intern, Poland",
      testimonial:"Working with them is a great opportuinty to gather international young engineers and other people who focus on the best way of using wastes. Being a part of it, I came to know many aspects of wastes problem and it also realized me there are many better system to purificate the Earth than dumping, landfilling or inceneration."
    },
];

function OurSupportersSection() {

const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="">
        <div className="about-us-section">
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span>Our </span>
            <span style={{fontWeight:"510",color:"green"}}>Sup</span>
            <span style={{fontWeight:"510",color:"#e6b800"}}>porters</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"30px", marginBottom:"20px"}}>
            <Col lg="4" className="text-center">
                <div style={{
                zIndex:"-1",
                paddingTop:"65px"
                }}>
                <img
                    alt="..."
                    height={"120px"}
                    src={require("../../assets/img/towaso/ESRI_logo.png")}
                />
                </div>
            </Col>
            <Col lg="8">
                <Row>
                    <Col lg="4" className="text-center">
                    <div style={{
                        zIndex:"-1",
                        }}>
                        <img
                            alt="..."
                            src={require("../../assets/img/towaso/icici-bank-vector-logo.svg")}
                        />
                    </div>
                    </Col>
                    <Col lg="4" className="text-center">
                    <div style={{
                        zIndex:"-1",
                        }}>
                        <img
                            alt="..."
                            src={require("../../assets/img/towaso/jnac-logo.jpeg")}
                        />
                    </div>
                    </Col>
                    <Col lg="4" className="text-center">
                    <div style={{
                        zIndex:"-1",
                        }}>
                        <img
                            alt="..."
                            src={require("../../assets/img/towaso/Jharkhand_emblem.png")}
                        />
                        <div className="text-center">Dept. of Information Technology & e-Governance</div>
                    </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row >
        <Col className="ml-auto mr-auto" md="11">
            <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <Row className="">
                            <Col md="4" sm="4" lg="2" className="ml-auto mr-auto text-center">
                            <div className="text-center">
                            <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={item.src}
                            />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                        <Col md="12" sm="12" lg="12" className="text-center">
                        <div style={{fontWeight:"400",fontSize:"18px", color:"#00802b"}}>{item.name}</div>
                        <div style={{fontWeight:"400",fontSize:"18px"}}>{item.about}</div>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm="12" md="12" lg="12" style={{fontSize:"18px",margin:"10px 70px 50px 70px", fontWeight:"300"}}>
                        <div style={{width:"86%"}} className="text-muted">
                          {item.testimonial}
                        </div>
                        </Col> 
                        </Row>
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default OurSupportersSection;
