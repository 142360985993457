import React from "react";

// core components
import LandingPageHeader from "./PolicyContent";
import MainFooter from "../under-dev/MainFooter";


function LandingPage() {
  
  return (
    <>
      <LandingPageHeader />
      <MainFooter/>
    </>
  );
}

export default LandingPage;
