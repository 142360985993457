import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
// core components
import "./landing.scss";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            setVisible(entry.isIntersecting);
            //observer.unobserve(entry.target);
        });
      }, {rootMargin: "0px 0px -500px 0px"});
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
}

function ProblemsSolveSection() {
  return (
    <>
      <div className="">
        <div className="problem-solve-section" 
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),url(" + require("assets/img/towaso/problems-img.png") + ")",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="10" md="10" lg="8" xl="8">
            <div style={{fontSize:"22px", color:"white",fontWeight:"400"}}>
                <span>Problems </span>
                <span style={{fontWeight:"510",color:"green"}}>Our </span>
                <span style={{fontWeight:"510",color:"#FEC325"}}>Team </span>
                <span>is trying to solve:</span>
            </div>
            </Col>
        </Row>
        <FadeInSection>
          <Row>
          <Col sm="2" md="2" lg="5" xl="5">
          <div className="">
            <div style={{color:"#F44336",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
            <br/>
              more than
            </div>
            <div style={{color:"#F44336",fontSize:"203px"}}>
              70%
            </div>
          </div>
          </Col>
          <Col sm="10" md="10" lg="7" xl="7">
          <div className="">
            <div style={{color:"white",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
              <br/>
              <span style={{color:"#F44336"}}>Loss in Monthly Revenue</span> for 
              the<span style={{color:"#286700"}}> Municipalities </span>
              from Door to Door Collection of Solid Waste
              <span style={{color:"#FEC325"}}> due to No tracking </span>
              and Monitoring of the Services.
              <br/>
              <br/>
              <br/>
            </div>
          </div>
          </Col>
          </Row>
        </FadeInSection>
        <FadeInSection>
          <Row>
          <Col sm="2" md="2" lg="5" xl="5">
          <div className="">
            <div style={{color:"#F44336",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
            <br/>
              more than
            </div>
            <div style={{color:"#F44336",fontSize:"203px"}}>
              95%
            </div>
          </div>
          </Col>
          <Col sm="10" md="10" lg="7" xl="7">
          <div className="">
            <div style={{color:"white",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
              <br/>
              <span style={{color:"#286700"}}>Urban Local Bodies </span> suffer from the Problem of <span style={{color:"#F44336"}}> Source Segregation </span> 
              which increases the Amount of Garbage in theDumpsite/ Landfill leading 
              to an <span style={{color:"#FEC325"}}> increase in the Carbon Footprint.</span>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
          </Col>
          </Row>
        </FadeInSection>
        <FadeInSection>
          <Row>
          <Col sm="2" md="2" lg="5" xl="5">
          <div className="">
            <div style={{color:"#F44336",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
            <br/>
              less than
            </div>
            <div style={{color:"#F44336",fontSize:"203px"}}>
              15%
            </div>
          </div>
          </Col>
          <Col sm="10" md="10" lg="7" xl="7">
          <div className="">
            <div style={{color:"white",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
              <br/>
              <span style={{color:"#F44336"}}>Waste </span> treated in <span style={{color:"#286700"}}> India </span> treated because 
              there is <span style={{color:"#FEC325"}}> no tracking and monitoring </span> of Source Segregation.
              <br/>
              <br/>
              <br/>
            </div>
          </div>
          </Col>
          </Row>
        </FadeInSection>
        <FadeInSection>
          <Row>
          <Col sm="2" md="2" lg="5" xl="5">
          <div className="">
            <div style={{color:"#F44336",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
            <br/>
              more than
            </div>
            <div style={{color:"#F44336",fontSize:"203px"}}>
              90%
            </div>
          </div>
          </Col>
          <Col sm="10" md="10" lg="7" xl="7">
          <div className="">
            <div style={{color:"white",fontSize:"48px", paddingTop:"90px"}} className="font-weight-bold">
              <br/>
              <span style={{color:"#F44336"}}>Waste Management Companies </span> in India are 
              suspended at one ULB 
              or the other because there is <span style={{color:"#FEC325"}}> No Data Driven Accountability </span>
              among the <span style={{color:"#286700"}}> Stakeholders</span>.
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
          </Col>
          </Row>
        </FadeInSection>
        </Container>
        </div>
      </div>
    </>
  );
}

export default ProblemsSolveSection;
