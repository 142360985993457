import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
// core components
import "./landing.scss";

function AboutUsSection() {
  return (
    <>
      <div id="about-citigen" className="">
        <div className="about-us-section" style={{
        backgroundColor:"#FFF1E4"
        //backgroundImage:
        //  "url(" + backgroundImageUrl + ")",
      }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
          <Row>
          <Col sm="12" md="12" lg="5" xl="5">
          <div className="">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
              <span>About </span>
              <span style={{fontWeight:"510",color:"#286700"}}>City</span>
              <span style={{fontWeight:"510",color:"#FEC325"}}>Gen</span>
              </div>
            <div style={{color:"black",fontWeight:"400", fontSize:"22px",paddingTop:"70px"}}>
            <br/>
            CityGen is an Urban Planning Tool  for the Municipalities to manage its Municipal
             Solid Waste and a Citizen Engagement Platform for the citizens with 
             Real Time tracking and Monitoring of the Municipal Solid Waste Management 
             Supply Chain.</div>
            <div style={{color:"black",fontWeight:"400", fontSize:"22px", paddingTop:"20px"}}>
            CityGen is an Urban Planning Tool  
            for the Municipalities to manage its Municipal Solid Waste 
            and a Citizen Engagement.</div>
              <br/>
              <br/>
              <br/>
          </div>
          </Col>
          <Col sm="12" md="12" lg="7" xl="7">
          <Row style={{marginTop:"75px", marginLeft:"20px"}}>
            <Col sm="3" md="3" lg="3" xl="3">
            <div style={{
              zIndex:"-1",
              }}>
              <img
                alt="..."
                src={require("../../assets/img/towaso/happy1_1-01 1.png")}
              />
              </div>
            </Col>
            <Col sm="3" md="3" lg="3" xl="3">
            <div style={{
              zIndex:"-1",
              }}>
              <img
                alt="..."
                src={require("../../assets/img/towaso/happy1_1-01 2.png")}
              />
              </div>
            </Col>
            <Col sm="3" md="3" lg="3" xl="3">
            <div style={{
              zIndex:"-1",
              }}>
              <img
                alt="..."
                src={require("../../assets/img/towaso/happy1_1-01 3.png")}
              />
              </div>
            </Col>
            <Col sm="3" md="3" lg="3" xl="3">
            <div style={{
              zIndex:"-1",
              }}>
              <img
                alt="..."
                src={require("../../assets/img/towaso/happy1_1-01 4.png")}
              />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" xl="12">
              <div style={{
              position: "absolute",
              right:"0px",
              top: "80px",
              zIndex:"-1",
              paddingLeft:"60px"
              }}>
              <img
                alt="..."
                height="120%"
                width="120%"
                src={require("../../assets/img/towaso/Van 1.png")}
              />
            </div>
            <div style={{
              position: "absolute",
              top:"20px",
              left:"140px",
              zIndex:"1",
              paddingLeft:"60px"
              }}>
              <img
                alt="..."
                src={require("../../assets/img/towaso/wastepickerhappy-01 1.png")}
              />
            </div>

            </Col>
          </Row>
          </Col>
          </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsSection;
