import React from "react";
// reactstrap components
import {Container, Col, Row, Button, Card } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

// core components
import "./about-us.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  rootProgress:{
    display:"flex",
    '& > * + *': {
      marginLeft: theme.spacing(4),
    },
  }
}));

function ContactUs() {
  const classes = useStyles();

  const [senderName, setSenderName] = React.useState('');
  const [senderEmail, setSenderEmail] = React.useState('');
  const [senderMessage, setSenderMessage] = React.useState('');
  const [isInvalidEmail, setIsInvalidEmail] = React.useState(false);
  const [isInvalidName, setIsInvalidName] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState("");
  const [errorName, setErrorName] = React.useState("");
  const [sendDisabled, setSendDisabled] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);

  const handleChange = (event) => {
    if(event.target.name==="name"){
      setSenderName(event.target.value);
    }
    else if(event.target.name==="email"){
      setSenderEmail(event.target.value);
    }
    else if(event.target.name==="message"){
      setSenderMessage(event.target.value);
    }
    
  };

  const handleMessageSend = () =>{
    //e.preventDefault();
    setSendDisabled(true);

    if(!senderName){
      setIsInvalidName(true);
      setErrorName("Please enter your name.");
      setSendDisabled(false);
      return;
    }
    else{
      setIsInvalidName(false);
    }
    if(!senderEmail){
      setIsInvalidEmail(true);
      setErrorEmail("Please enter your email address.");
      setSendDisabled(false);
    }
    else if(senderEmail!=="undefined"){
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(senderEmail)) {
        setIsInvalidEmail(true);
        setErrorEmail("Please enter valid email address.")
        setSendDisabled(false);
        return;
      }
      else{
        setIsInvalidEmail(false);
      }
    }

    if(!isInvalidName && !isInvalidEmail){

      if(!senderEmail || isInvalidEmail){
        return;
      }
      const templateParams = {
        from_name: senderName + " (" + senderEmail + ")",
        reply_to: senderEmail,
        to_name:"Team Citygen",
        message: senderMessage
      };

      setShowProgress(true);
      emailjs.send("service_oc8qwfr", "contactUsForm", templateParams, "user_hwy195IMkI5aIHBQZajdF").then((result) => {
        console.log(result.text);
        setSenderEmail("");
        setSenderName("");
        setSenderMessage("");
        Swal.fire({
          title: 'Message Successfully Sent.',
          icon: 'success'
        });
        setSendDisabled(false);
        setShowProgress(false);
        }, (error) => {
          console.log(error.text);
          Swal.fire({
            title: 'Failed to Send Message. Please try again later.',
            icon: 'error'
          });
          setSendDisabled(false);
          setShowProgress(false);
      });
    }

  };

  return (
    <>
      <div id="contact" className="">
        <div className="about-us-section"
        style={{
            //backgroundColor:"#FFF1E4",
            //backgroundImage:"url(" + require("assets/img/towaso/news-section-bg.png") + ")"
        }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span style={{fontWeight:"510",color:""}}>Contact</span>
            <span style={{fontWeight:"510",color:"#286700"}}> U</span>
            <span style={{fontWeight:"510",color:"#FEC325"}}>s</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"60px"}}>
        <Col md="11" lg="11" className="ml-auto mr-auto">
          <Card className="no-transition" style={{minHeight:"72vh", padding: "15px"}}>
            <Row>
              <Col style={{paddingLeft:"60px", paddingTop:"60px"}}>
              <div style={{fontSize:"20px"}}>
                Leave us a message
              </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6" style={{paddingLeft:"60px"}}>
              <form className={classes.root} noValidate autoComplete="off" style={{width:"100%", marginTop:"30px"}}>
                <TextField 
                id="name"
                name="name"
                label="Name" variant="outlined" 
                style={{width:"100%"}}
                onChange={(e) => {handleChange(e);}}
                value={senderName}
                error={isInvalidName}
                />
                {isInvalidName && <FormHelperText id="name-error-text" style={{color:"red", width:"100%"}}>{errorName}</FormHelperText>}
                <TextField 
                id="email"
                name="email" 
                label="Email Address" 
                variant="outlined" 
                style={{width:"100%", marginTop:"22px"}}
                onChange={(e) => {handleChange(e);}}
                value={senderEmail}
                error={isInvalidEmail}
                aria-describedby="email-error-text"
                />
                {isInvalidEmail && <FormHelperText id="email-error-text" style={{color:"red", width:"100%"}}>{errorEmail}</FormHelperText>}
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  multiline
                  rows={4}
                  variant="outlined"
                  style={{width:"100%", marginTop:"22px"}}
                  onChange={(e) => {handleChange(e);}}
                  value={senderMessage}
                />
              </form>
              <Button className="btn-round text-center" 
              style={{borderColor:"#286700", backgroundColor:"white", marginTop:"30px"}} 
              type="button" outline size="lg"
              disabled={sendDisabled}
              onClick={()=>{handleMessageSend();}}
              >
              <span style={{color:"#286700"}}>Send</span>
              </Button>
              {showProgress &&<CircularProgress className={classes.rootProgress} style={{}} color="inherit" />}
              </Col>
              <Col lg="6" style={{paddingLeft:"80px", paddingTop:"40px",fontSize:"16px"}}>
                <Row>
                  <Col style={{marginTop:"10px"}}>
                  <div>
                    <span>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/location.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span style={{marginLeft:"20px"}}>Humpipe Road, Jamshedpur-831001</span>
                    <span></span>
                  </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginTop:"10px"}}>
                  <div>
                  <span>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/phone.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span style={{marginLeft:"20px"}}>+91-8235639783, +91-8009544907</span>
                  </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginTop:"10px"}}>
                  <div>
                    <span>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/mail.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span style={{marginLeft:"20px"}}>citygenerp@gmail.com</span>
                  </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginTop:"10px"}}>
                  <div>
                  <span tag="a" style={{cursor:"pointer"}}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.youtube.com/channel/UCWXFX3QT2TJZIvkHKbmp0uA", "_blank")
                    }}>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Youtube.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span tag="a" style={{marginLeft:"20px", cursor:"pointer"}}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://twitter.com/towasoIND", "_blank")
                    }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Twitter-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span tag="a" style={{marginLeft:"20px", cursor:"pointer"}} 
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.facebook.com/towasoWM", "_blank")
                    }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Facebook.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span tag="a" style={{marginLeft:"20px", cursor:"pointer"}} 
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("mailto:citygenerp@gmail.com", "_blank")
                    }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/mail.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span tag="a" style={{marginLeft:"20px", cursor:"pointer"}} 
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.linkedin.com/company/citygen/about/", "_blank")
                    }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/linkedin-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                  </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginTop:"25px"}}> 
                  <div>
                  <img
                      alt="..."
                      src={require("../../assets/img/towaso/map-dummy.png")}
                      style={{borderRadius:"0"}}
                    />
                  </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
