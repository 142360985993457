import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
// core components
import "./about-us.scss";

function MissionVission() {
  return (
    <>
      <div className="mission-vission">
      <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
          <Col md="12" lg="6">
            <Row>
              <Col sm="12" md="12" lg="7" xl="7">
              <div style={{fontSize:"24px", color:"black",fontWeight:"400"}}>
              <span style={{fontWeight:"510",color:"black"}}>Our</span>
              <span style={{fontWeight:"510",color:"#286700"}}> Vision</span>
              </div>
              </Col>
            </Row>
            <Row style={{marginTop:"30px"}}>
              <Col>
              <div style={{fontSize:"24px", color:"black"}}>
              To Manage Waste Magnificently by Utilizing Resources before they get wasted.
              </div>
              </Col>
            </Row>
          </Col>
          <Col md="12" lg="6">
            <Row>
              <Col sm="12" md="12" lg="7" xl="7">
              <div style={{fontSize:"24px", color:"black",fontWeight:"400"}}>
              <span style={{fontWeight:"510",color:""}}>Our</span>
              <span style={{fontWeight:"510",color:"#FEC325"}}> Mission</span>
              </div>
              </Col>
            </Row>
            <Row style={{marginTop:"30px"}}>
              <Col>
              <div style={{fontSize:"24px", color:"black"}}>
              To Create the most compelling Tech Enabled Waste Management 
              company of the 21st Century which fuels the development of Energy from Waste.
              </div>
              </Col>
            </Row>
          </Col>
        </Row>
        </Container>
        </div>
    </>
  );
}

export default MissionVission;