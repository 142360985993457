import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// reactstrap components
import {Container, Col, Row, Button, Card } from "reactstrap";
// core components
import "./landing.scss";

const newsItems = [
  // {
  //   id:"0",
  //   headingImg:require("assets/img/towaso/newsStory1.png"),
  //   newsHeading:"TowaSo: Solutions to Make the World, A Better Place",
  //   newsSummary:"Insights Success: As human being and thinking individuals, we come across a wide range of issues in our daily life. Some problems are personal while some on the social level. Every one of us is inherently programmed to react to such problems in one way or the other...",
  //   pageLink:"https://www.insightssuccess.in/towaso-solutions-make-world-better-place/",
  // },
  {
    id:"1",
    headingImg:require("assets/img/towaso/jharkhand-innovation-green-low-cost-toilet-cleaner.jpg"),
    newsHeading:"Orange Peels Keep Toilets Clean: Jharkhand Man Invents Eco-Friendly Solution",
    newsSummary:"The Better India: A total of 58 community and public toilets in the steel city suffered the common issues of most toilets–unbearable stink. The use of strong chemicals kept the toilets and the tiles clean, but it also reduced the bacterial growth in the septic tank...",
    pageLink:"https://www.thebetterindia.com/170732/jharkhand-innovation-green-low-cost-toilet-cleaner/",
  },
  // {
  //   id:"2",
  //   headingImg:require("assets/img/towaso/wastemanagment.png"),
  //   newsHeading:"इंजीनियर सौरभ कुमार के टोवेसो (टोटल वेस्ट सोल्यूशन) स्टार्टअप में मिला 200 लोगों को रोजगार",
  //   newsSummary:"Your Story: Towaso has increased the revenue of  its partner  Urban Local Bodies by more than 4 times which has helped in creating jobs for 200 people with 2 Urban Local Bodies i.e JNAC and Jugsulai...",
  //   pageLink:"https://yourstory.com/hindi/engineer-saurabh-kumars-towaso-startup-employs-200/",
  // },
  {
    id:"3",
    headingImg:require("assets/img/towaso/19_01_2021-mahila_samiti_21286752.jpg"),
    newsHeading:"Swachh survekshan 2021: बेकार चायपत्ती से बन रही खाद,जमशेदपुर के जुगसलाई को नंबर वन बनाने की राह होगी आसान",
    newsSummary:"Jagran: Towaso (Towards Solution Pvt. Ltd.) helped solve the problem of Tea waste by converting the waste into organic fertiliser with the help of Self Help Groups. Currently 15 shops provide 30 Kgs of Tea Waste Daily...",
    pageLink:"https://www.jagran.com/jharkhand/jamshedpur-swachh-survekshan-2021-the-way-to-make-jugsalai-of-jamshedpur-number-one-21286752.html/",
  },
  {
    id:"4",
    headingImg:require("assets/img/towaso/citrus-fruits-waste.jpg"),
    newsHeading:"Citrus Fruit Waste To Keep Public Toilets Clean In Jamshedpur",
    newsSummary:"NDTV: While the central government is on a toilet building spree under Swachh Bharat Abhiyan, maintaining the newly constructed toilets is another vital aspect for a Clean India that gets ignored. The situation was no different in a community toilet in Jharkhand’s...",
    pageLink:"https://swachhindia.ndtv.com/jamshedpur-waste-management-citrus-fruit-waste-for-clean-public-toilets-30285/",
  },
  // {
  //   id:"5",
  //   headingImg:require("assets/img/towaso/ac063f17-c5ef-4a1a-9115-b526f4c29e7e.jpg"),
  //   newsHeading:"Towaso, a Waste Management Startup  taps citrus fruit waste to keep loo’s clean",
  //   newsSummary:"Telegraph India: TowaSo, an acronym for Towards Solution Private Limited , has joined hands with Jamshedpur Notified Area Committee (JNAC) to keep 58 community toilets in and around the steel city squeaky clean with the help of bio-enzymes...",
  //   pageLink:"https://www.telegraphindia.com/jharkhand/start-up-founded-by-students-and-alumni-of-iit-ism-taps-bio-enzymes-fromcitrus-fruit-waste-to-keep-loos-clean/cid/1679253/",
  // },
  {
    id:"6",
    headingImg:require("assets/img/towaso/20DhanISM1.jpg"),
    newsHeading:"Hurrah! Big pat for ISM waste solution",
    newsSummary:"Telegraph India: A waste management start-up, launched by a group of 27 ISM students in Dhanbad, will win national laurels at a glittering ceremony in Delhi on Friday. Towaso, debuted in February 2016, collects garbage from every door in Ward 20 under Dhanbad Municipal Corporation (DMC)...",
    pageLink:"https://www.telegraphindia.com/jharkhand/hurrah-big-pat-for-ism-waste-solution/cid/1328828/"
  }
];

function NewsSection() {
    let pageHeader = React.createRef();

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

  return (
    <>
      <div className="">
        <div className="about-us-section"
        style={{
            //backgroundColor:"#FFF1E4"
            backgroundImage:"url(" + require("assets/img/towaso/news-section-bg.png") + ")"}}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span style={{fontWeight:"510",color:"#286700"}}>Our </span>
            <span style={{fontWeight:"510",color:"#FEC325"}}>Team </span>
            <span style={{fontWeight:"510",color:"white"}}> in News</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"60px"}}>
        <Col>
        <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={300}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        //deviceType={this.props.deviceType}
        //dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
        {newsItems.map((data, key) => {
          return (
            <div key={key}>
            <Card className="no-transition">
            <div
                style={{
                backgroundImage:
                    "url(" + data.headingImg + ")",
                borderRadius:"12px 12px 0 0"
                }}
                className="page-header page-header-blog"
                data-parallax={true}
                ref={pageHeader}
            >
            </div>
            <Row style={{padding:"20px 25px 10px 25px", height:"55vh"}}>
                <Col>
                <Row>
                <Col className="font-weight-bold" style={{fontSize:"20px"}}>
                {data.newsHeading} 
                </Col>
                </Row>
                <Row style={{paddingTop:"10px"}}>
                    <Col style={{color:"#767676", fontSize:"16px", fontWeight:"400"}}>
                    {data.newsSummary} 
                    </Col>
                </Row>
                <Row style={{paddingTop:"10px"}}>
                    <Col sm="6" md="6" className="text-center" style={{position:"absolute", bottom:"10px"}}>
                    <Button className="btn-link mr-1" color="default"
                    href={data.pageLink}
                    target="_blank"
                    //onClick={(e) => e.preventDefault()}
                    >
                    Read More
                    </Button>
                    </Col>
                </Row>
                </Col>
            </Row>
        </Card>
        </div>
        );
        })}
        </Carousel>
        </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default NewsSection;
