import React from "react";
// reactstrap components
import LandingHeaderText from "./HeaderText";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  //const backgroundImageUrl = window.innerWidth>=768? require("assets/img/towaso/towaso-landing-bg.png") : require("assets/img/towaso/Towaso-home-phone.png");

  return (
    <>
      <div
        style={{
          backgroundColor:"black"
          //backgroundImage:
          //  "url(" + backgroundImageUrl + ")",
        }}
        className="page-header page-header-mobile"
        data-parallax={true}
        ref={pageHeader}
      >
        { window.innerWidth>=768 && <LandingHeaderText/>}
      </div>
    </>
  );
}

export default LandingPageHeader;
