/*eslint-disable*/
import React from "react";
import "./footer.scss";

// reactstrap components
import { 
    Col, 
    Row, 
    Container,
    NavbarBrand,
    NavItem,
    NavLink,
    Nav, } from "reactstrap";
import { Link } from "react-router-dom";

function MainFooter() {

  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row style={{marginTop:"60px", padding: 15}}>
            <Col lg="6">
              <Row>
                <Col lg="9">
                  <NavbarBrand
                    data-placement="bottom"
                    to="/home"
                    title="TowaSo"
                    tag={Link}
                  >
                  <img
                      style={{height: "100px"}}
                      alt="..."
                      src={require("../../assets/img/towaso/citygen-footer-logo.png")}
                  />
                  </NavbarBrand>
                  <div style={{fontSize:"18px", color:"#252525", fontWeight:"400", marginTop:"41px"}}>
                  Digitising the City with Unique Building Identity.
                  </div>
                  {/* <div className="credits ml-auto"> */}
                  <span className="copyright" style={{fontSize:"16px", fontWeight:"400"}}>
                  Citygen Technology Pvt. Ltd. {new Date().getFullYear()}. All Rights Reserved.
                  </span>
                  {/* </div> */}
                </Col>
              </Row>
            </Col>
            {/* <Col lg="3">
              <Row>
                <Col lg="10" className="ml-auto mr-auto">
                <Nav navbar>
                <NavItem>
                  <NavLink to="/" tag={Link} style={{height:"36px"}}>
                    <span className="footer-nav footer-nav-item" style={{fontSize:"20px", fontWeight:"700"}}>Citygen</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/home" tag={Link} style={{height:"36px"}}>
                    <span className="footer-nav footer-nav-item">Home</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about" tag={Link} style={{height:"36px"}}>
                    <span className="footer-nav footer-nav-item">About Us</span> 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/home/#features" tag={Link} style={{height:"36px"}}>
                  <span className="footer-nav footer-nav-item">Our Solutions</span> 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about/#contact" tag={Link} style={{height:"36px"}}>
                  <span className="footer-nav footer-nav-item">Contact Us</span> 
                  </NavLink>
                </NavItem>
                </Nav>
                </Col>
              </Row>
            </Col> */}
            <Col lg="6" style={{color:"black", fontSize:"16px", fontWeight:"400"}}>
              <Row>
                <Col>
                <div style={{fontSize:"20px", fontWeight:"700", marginTop:"10px"}}>Contact us</div>
                </Col>
              </Row>
              <Row>
                <Col>
                <div>
                {/* <span>
                  <img
                    alt="..."
                    src={require("../../assets/img/towaso/phone.png")}
                    style={{borderRadius:"0"}}
                  />
                </span> */}
                <span style={{fontSize:"20px", fontWeight:"500"}}>Kapil Suhane</span>
                </div>
                <div>
                <span>
                  <img
                    alt="..."
                    src={require("../../assets/img/towaso/phone.png")}
                    style={{borderRadius:"0"}}
                  />
                </span>
                <span style={{marginLeft:"20px"}}>+91-8009544907</span>
                </div>
                <div>
                <span>
                  <img
                    alt="..."
                    src={require("../../assets/img/towaso/mail.png")}
                    style={{borderRadius:"0"}}
                  />
                </span>
                <span style={{marginLeft:"20px"}}>kapil.suhane16@gmail.com</span>
                </div>
                </Col>
              </Row>
              <Row>
                <Col>
                <div style={{fontSize:"16px", fontWeight:"400"}}>
                  <Nav navbar>
                  <NavItem>
                    <NavLink to="/privacy-policy" tag={Link}>
                      <span className="footer-nav footer-nav-item">See our Privacy Policy here.</span>
                    </NavLink>
                  </NavItem>
                  </Nav>
                  {/* <span style={{
                    cursor:"pointer"
                    }}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://citygen.ams3.digitaloceanspaces.com/Assets-Website/Citygen%20Privacy%20Policy.pdf", "_blank")
                    }}>
                    See our Privacy Policy here.
                  </span> */}
                </div>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                <div style={{fontSize:"20px", fontWeight:"700", marginTop:"10px"}}>Follow us</div>
                <div>
                  <span style={{
                    cursor:"pointer"
                    }}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.youtube.com/channel/UCWXFX3QT2TJZIvkHKbmp0uA", "_blank")
                    }}>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Youtube-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span style={{
                      marginLeft:"20px",
                      cursor:"pointer"
                      }}
                      tag="a"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("http://www.google.com", "_blank")
                      }}  
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Instagram-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span style={{
                      marginLeft:"20px",
                      cursor:"pointer"
                    }}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.facebook.com/towasoWM", "_blank")
                    }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Facebook-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span 
                    tag="a"
                    style={{
                      marginLeft:"20px",
                      cursor:"pointer"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://twitter.com/towasoIND", "_blank")
                      }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/Twitter-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                    <span 
                    tag="a"
                    style={{
                      marginLeft:"20px",
                      cursor:"pointer"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.linkedin.com/company/citygen/about/", "_blank")
                      }}
                    >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/linkedin-2.png")}
                      style={{borderRadius:"0"}}
                    />
                    </span>
                </div>
                </Col>
              </Row> */}
            </Col>
          </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;