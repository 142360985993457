import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactCardFlip from "react-card-flip";

// reactstrap components
import {Container, Col, Row, Card } from "reactstrap";
// core components
import "./about-us.scss";

const teamData =[
  {
    id:0,
    name:"Saurabh Kumar",
    designation:"Co-Founder & Director",
    about:"Saurabh is a Mechanical Engineer who graduated from IIT ISM Dhanbad in 2016 and a post graduate in Environmental Engineering from IIT ISM Dhanbad, Batch of 2018. Saurabh has been working into the Waste Management Sector as a consultant for 4 years now and has won laurels in Jharkhand for his Award winning methods of Managing Waste Magnificently.",
    email:"saurabh.ism16@gmail.com",
    linkedin:"https://www.linkedin.com/in/saurabhism16/",
    img:require("assets/img/towaso/sourav.png")
  },
  {
    id:1,
    name:"Kapil Suhane",
    designation:"Co-Founder & Director",
    about:"Kapil is an Engineer graduated from IIT ISM Dhanbad in 2019. Kapil has worked with Schlumberger as a Consultant along with 20 different Nationalities. While as a student Kapil has managed & raised an alumni fund of 1.2 Million USD & has also been awarded Economic Times Campus Star Award 2019.",
    email:"kapil.suhane16@gmail.com",
    linkedin:"https://www.linkedin.com/in/suhanekapil/",
    img:require("assets/img/towaso/kapil.png")
  },
  // {
  //   id:2,
  //   name:"Soni Kumari",
  //   designation:"Co-Founder",
  //   about:"Soni has a Masters in Zoology . Soni has been working into Waste Management sector as a consultant for 4 years now. Soni is experienced in Agri-waste management, aquaculture and Municipal Waste Management and has won laurels for her Techniques in Managing Floral Waste.",
  //   email:"",
  //   linkedin:"",
  //   img:require("assets/img/towaso/soni.png")
  // }
]

function TeamTowaso() {
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
  };

  const handleMouseEnter = function (id) {
    if(id===0){
      setFlippedList([true,false,false]);
    }
    if(id===1){
      setFlippedList([false,true,false]);
    }
    if(id===2){
      setFlippedList([false,false,true]);
    }
  }

  const handleMouseLeave = function(){
    setFlippedList([false,false,false]);
  }

  const[flippedList, setFlippedList] = React.useState([false,false,false]);
  
  return (
    <>
      <div className="">
        <div className="about-us-section"
        style={{
            //backgroundColor:"#FFF1E4",
            //backgroundImage:"url(" + require("assets/img/towaso/news-section-bg.png") + ")"
        }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span style={{fontWeight:"510",color:""}}>Team</span>
            <span style={{fontWeight:"510",color:"#286700"}}> City</span>
            <span style={{fontWeight:"510",color:"#FEC325"}}>Gen</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"60px"}}>
        <Col>
        <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={300}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
        //deviceType={this.props.deviceType}
        //dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
        {teamData.map((data, key) => {
          return (
            <div key={key}>
            <ReactCardFlip isFlipped={flippedList[data.id]} flipDirection="horizontal">
            <Card className="no-transition CardFront" style={{backgroundColor: "#FEC325"}} 
            onMouseEnter={() => handleMouseEnter(data.id)} >
              <Row className="text-center" style={{padding:"20px 25px 10px 25px", height:"70vh"}}>
                <Col>
                <Row>
                  <Col className="text-center">
                    <img
                      alt="..."
                      src={data.img}
                      style={{borderRadius:"0"}}
                    />
                  </Col>
                </Row>
                <Row style={{marginTop:"20px"}}>
                <Col style={{fontSize:"24px"}}>
                {data.name}
                </Col>
                </Row>
                <Row style={{paddingTop:"10px"}}>
                    <Col style={{fontSize:"18px"}}>
                    {data.designation}
                    </Col>
                </Row>
                </Col>
              </Row>
            </Card>
            <Card className="no-transition CardBack" style={{}}
            onMouseLeave={() => handleMouseLeave()}
            >
              <Row className="text-center" style={{padding:"20px 25px 10px 25px", height:"70vh"}}>
                <Col>
                <Row style={{marginTop:"5px"}}>
                <Col style={{fontSize:"24px"}}>
                {data.name}
                </Col>
                </Row>
                <Row style={{marginTop:"5px"}}>
                    <Col style={{fontSize:"18px"}}>
                    {data.designation}
                    </Col>
                </Row>
                <Row style={{marginTop:"25px"}}>
                  <Col>
                  <span
                    style={{
                      cursor: "pointer"
                    }}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${data.linkedin}`, "_blank");
                    }}
                  >
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/LinkedIn.png")}
                      style={{borderRadius:"0"}}
                    />
                  </span>
                  <span style={{marginLeft:"20px", cursor: "pointer"}}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`mailto:${data.email}`, "_blank");
                    }}>
                    <img
                      alt="..."
                      src={require("../../assets/img/towaso/gmail.png")}
                      style={{borderRadius:"0"}}
                    />
                  </span>
                  </Col>
                </Row>
                <Row className="" style={{marginTop:"25px"}}>
                    <Col lg="11" className="text-muted ml-auto mr-auto" style={{fontSize:"17px"}}>
                    {data.about}
                    </Col>
                </Row>
                </Col>
              </Row>
            </Card>
            </ReactCardFlip>
          </div>
          );
          })}
        </Carousel>
        </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default TeamTowaso;
