import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// reactstrap components
import {Container, Col, Row, Card } from "reactstrap";
// core components
import "./landing.scss";

const stackholderData = [
  {
    id:"0",
    imgPath:require("assets/img/towaso/supervisorhappy-01 1.png"),
    title:"Municipalities",
    heading:"",
    advantages:[
      "3X Increase in Revenue from Door to Door Collection.",
      "3X Increase in Source Segregation.",
      "At least 2X Increase in the Waste Collection Efficiency creating Efficient Use of the Work-Force.",
      "Data Driven Service Tracking and Accountability amongst the stakeholders of the Ecosystem."
    ]
  },
  {
    id:"1",
    imgPath:require("assets/img/towaso/jayhappy-01 1.png"),
    title:"Households",
    heading:"",
    advantages:[
      "Pay only for the Number of Days Served.",
      "Get Notified Before the Arrival of the Waste Collector.",
      "Get Paid for Waste Segregation in the form of Carbon Credits.",
      "On Demand Service for Kabadiwallah.",
      "One Stop Platform for raising your Complaints and requests with the Municipal Corporation and District Authorities."
    ]
  },
  {
    id:"2",
    imgPath:require("assets/img/towaso/wastepicker.png"),
    title:"Kabadiwallah",
    heading:"",
    advantages:[
      "40% Increase in Scrap Collection Efficiency.",
      "Increase in the Average wage for the Kabadiwallah by 30%.",
      "Social Benefits  such as  Education and Medical Insurance for the Families."
    ]
  },
  {
    id:"3",
    imgPath:require("assets/img/towaso/managerhappy-01 1.png"),
    title:"Waste Collection and Recycling Centres",
    heading:"",
    advantages:[
      "On Demand Waste Raw Material Procurement for the Recyclers.",
      "AI Driven Supply Chain Analytics and Inference for their Inventory Management",
      "Real Time Tracking and Monitoring of the Linked Kabadiwallahs / Door to Door Scrap-Dealers."
    ]
  }
];

function StackHolderSection() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          //paritialVisibilityGutter: 200
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          //paritialVisibilityGutter: 100
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          //paritialVisibilityGutter: 50
        }
    };

  return (
    <>
      <div className="">
        <div className="about-us-section"
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),url(" + require("assets/img/towaso/stackholder-bg.png") + ")",
        }}>
        <div style={{
            position: "absolute", 
            top: "60",
            left: "0",
            zIndex:"-1",
            paddingTop:"5px"}}>
            <svg width="36" height="11" style={{margin:"0"}}>
            <rect width="36" height="11" style={{fill:"#FEC325"}} />
            </svg>
        </div>
        <Container>
        <Row>
            <Col sm="12" md="12" lg="7" xl="7">
            <div style={{fontSize:"22px", color:"black",fontWeight:"400"}}>
            <span style={{color:"white"}}>A recap: Our </span>
            <span style={{fontWeight:"510",color:"green"}}>Stake</span>
            <span style={{fontWeight:"510",color:"#e6b800"}}>Holders</span>
            </div>
            </Col>
        </Row>
        <Row style={{marginTop:"60px", overflow:"visible"}}>
        <Col lg="10" className="ml-auto mr-auto">
        <Carousel
        //partialVisbile
        swipeable={false}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={300}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
        //deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
        {stackholderData.map((data, key) => {
          return (
            <div key={key}>
            <Card className="no-transition">
            <Row style={{height:"63vh"}}>
                <Col sm="4" md="4" lg="4">
                <div style={{
                  position:"absolute",
                  bottom:"0"
                  }}>
                  <img
                    alt="..."
                    src={data.imgPath}
                    style={{paddingLeft:"10px", height: "390px"}}
                  />
                </div>
                </Col>
                <Col sm="8" md="8" lg="8">
                <Row style={{marginTop:"75px", paddingLeft:"20px"}}>
                <Col className="font-weight-bold" style={{fontSize:"24px"}}>
                {data.title}
                </Col>
                </Row>
                <Row style={{marginTop:"20px", paddingLeft:"20px"}}>
                    <Col lg="10" style={{color:"#767676", fontSize:"18px", fontWeight:"400"}}>
                    {data.heading}
                    </Col>
                </Row>
                <Row style={{marginTop:"20px", paddingLeft:"20px", fontSize:"18px"}}>
                    <Col lg="10">
                    <div>
                      {data.advantages.map((feature, key) => {
                        return(
                          <div key={key}>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          <span style={{marginLeft:"10px"}}>{feature}</span>
                          </div>
                        );
                      })}
                    </div>
                    </Col>
                </Row>
                </Col>
            </Row>
        </Card>
        </div>
        );
        })}
        </Carousel>
        </Col>
        </Row>
        </Container>
        </div>
      </div>
    </>
  );
}

export default StackHolderSection;
