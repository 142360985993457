import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import "../under-dev/landing.scss";
import "./policy.scss";
// core components

function LandingPageHeader() {
  let policyPageHeader = React.createRef();

  //const backgroundImageUrl = window.innerWidth>=768? require("assets/img/towaso/towaso-landing-bg.png") : require("assets/img/towaso/Towaso-home-phone.png");

  return (
    <>
        <div
            style={{
            backgroundColor:"black",
            opacity:"0.8"
            //backgroundImage:
            //  "url(" + backgroundImageUrl + ")",
            }}
            className="policy-page-header"
            data-parallax={true}
            ref={policyPageHeader}
        >
            <Row>
                <Col sm="12" md="12" lg="11" className="mx-auto text-center" style={{marginTop:"10vh"}}>
                <h1 style={{
                color:"white",
                fontSize:"54px", 
                fontWeight:"600px", 
                fontFamily:"ProductSansRegular"
                }} className="">
                Our Privacy Policy
                </h1>
                </Col>
            </Row>
        </div>
        <div>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"40px"}}>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular"
                }}>
                    Citygen Technology Pvt. Ltd. built the Citygen app/website “https://citygen.towaso.com” as a Free app/website. This SERVICE is provided by Citygen Technology Pvt. Ltd. at no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Citygen app unless otherwise defined in this Privacy Policy.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Information Collection and Use</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Phone number, Email ID, Name, image, location. The information that we request will be retained by us and used as described in this privacy policy. The personal information we are adding to the app is lawfully connected to our services and are necessary for the functionality of the app. The location of the users is used for locating households for onboarding them on the app. Most GPS enabled mobile devices can define one’s location to within 50 feet. The app/website does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the app/website -  
                <span style={{
                    color:"#0645AD",
                    cursor:"pointer"
                    }}
                    tag="a"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://policies.google.com/terms", "_blank")
                    }}>
                    Google Play Services
                </span>
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Location</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                The app takes location permission from users. Which is necessary for the functionality of the app. This can always be withdrawn but you will not be able to fully use the App and its benefits. We use that geo-location information for various purposes, including keeping data of certain workers' daily areas covered, onboarding buildings for workers to locate and pick up garbage.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Log Data</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                We want to inform you that whenever you use our Service, in a case of an error in the
                app/website we collect data and information (through third party products) on your phone called
                Log Data. This Log Data may include information such as your device Internet Protocol (“IP”)
                address, device name, operating system version, the configuration of the app/website when
                utilizing our Service, the time and date of your use of the Service, and other statistics.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Cookies</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                Cookies are files with a small amount of data that are commonly used as anonymous unique
                identifiers. These are sent to your browser from the websites that you visit and are stored on
                your device's internal memory. This Service does not use these “cookies” explicitly. However,
                the app/website may use third party code and libraries that use “cookies” to collect information
                and improve their services. You have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose to refuse our cookies, you may
                not be able to use some portions of this Service.<br/>
                <br/>
                ● To facilitate our Service<br/>
                ● To provide the Service on our behalf<br/>
                ● To perform Service-related services<br/>
                ● To assist us in analyzing how our Service is used<br/>
                ● We want to inform users of this Service that these third parties have access to your
                Personal Information. The reason is to perform the tasks assigned to them on our behalf.
                However, they are obligated not to disclose or use the information for any other purpose.<br/>
                <br/>
                We want to inform users of this Service that these third parties have access to your Personal
                Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any other purpose.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Security</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                The Protected Information and Usage Information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls and SSL (Secure Socket Layers) for protecting your information. However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that information you supply won't be intercepted while being transmitted to us over the Internet or wireless communication, and any information you transmit to the Company you do at your own risk. We recommend that you not disclose your password to anyone.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Links to Other Sites</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                This Service may contain links to other sites. If you click on a third-party link, you will be directed
                to that site. Note that these external sites are not operated by us. Therefore, we strongly advise
                you to review the Privacy Policy of these websites. We have no control over and assume no
                responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Children’s Privacy</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                These Services do not address anyone under the age of 13. We do not knowingly collect
                personally identifiable information from children under 13. In the case we discover that a child
                under 13 has provided us with personal information, we immediately delete this from our
                servers. If you are a parent or guardian and you are aware that your child has provided us with
                personal information, please contact us so that we will be able to do necessary actions.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Changes to This Privacy Policy</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                periodically for any changes. We will notify you of any changes by posting the new Privacy
                Policy on this page.<br/>
                This policy is effective as of 2021-10-01.
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10" md="10" lg="9" className="mx-auto" style={{marginTop:"25px"}}>
                <div style={{
                    fontSize:"30px", 
                    fontWeight:"500px", 
                    fontFamily:"ProductSansRegular"
                }}>Contact Us</div>
                <div style={{
                    fontSize:"20px", 
                    fontWeight:"400px", 
                    fontFamily:"ProductSansRegular",
                    marginTop: "5px"
                }}>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact the
                Grievance officer at citygenerp@gmail.com.
                </div>
                </Col>
            </Row>
        </div>
    </>
  );
}

export default LandingPageHeader;
