import React from 'react';

const BackgroundVideo = ({ videoSource,opacity }) => {
    //const videoSource = "https://www.w3schools.com/tags/movie.mp4"
    return (
    <video
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          width: "100%",
          left: 0,
          top: 0,
          opacity: opacity,
          transition: "opacity, 2s ease-in-out",
        }}
      >
        <source src={videoSource} type="video/mp4" />
      </video>
    )
}

export default BackgroundVideo