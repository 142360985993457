import React from "react";
// reactstrap components
import AboutusHeaderText from "./AboutusHeaderText";

// core components

function AboutusPageHeader() {
  let pageHeader = React.createRef();

  //const backgroundImageUrl = window.innerWidth>=768? require("assets/img/towaso/towaso-landing-bg.png") : require("assets/img/towaso/Towaso-home-phone.png");

  return (
    <>
      <div
        style={{
          //backgroundColor:"#FFF1E4"
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),url(" + require("assets/img/towaso/about-us-header.png") + ")"}}
        className="page-header page-header-mobile"
        data-parallax={true}
        ref={pageHeader}
      >
        { window.innerWidth>=768 && <AboutusHeaderText/>}
      </div>
    </>
  );
}

export default AboutusPageHeader;
