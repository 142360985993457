import React,{useState} from "react";
// reactstrap components
import { Button, Container, Col, Row } from "reactstrap";
import "./landing.scss";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';
import {
 faCaretRight,
 faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import BackgroundVideo from "components/BackgroundVideo/BackgroundVideo";

function LandingHeaderText() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
    <div className="bg-gray-sm">
    <BackgroundVideo videoSource="https://citygen.ams3.digitaloceanspaces.com/Assets-Website/XiaoYing_Video_1611777323958_HD.mp4" opacity="0.3"/>
    <Container>
        <Row>
            <Col sm="12" md="12" lg="11" className="mx-auto text-center" style={{marginTop:"10vh"}}>
            <h1 style={{
              color:"white",
              fontSize:"54px", 
              fontWeight:"600px", 
              fontFamily:"ProductSansRegular"
              }} className="">
              Get Your Waste Management Supply Chain Monitered and Digitised in Real time
              </h1>
            </Col>
        </Row>
        <Row className="mx-auto text-center">
            <Col>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="97RdJpZcRKQ" onClose={() => setOpen(false)} />
            <Button className="btn-round" style={{borderColor:"#286700", backgroundColor:"white", marginTop:"13vh"}} 
            type="button" outline size="lg"
            onClick={()=> setOpen(true)}>
            <span><FontAwesomeIcon icon={faCaretRight} size="2x" color="#286700"/></span>
            <span style={{color:"#286700", fontSize:"18px", fontWeight:"300px"}}> Watch Video</span>
            </Button>
            <br/>
            <br/>
            <Button className="btn-round text-center" style={{borderColor:"#286700", backgroundColor:"white", marginTop:"10vh", position:"absolute", display:"block",left:"46%"}} type="button" outline size="lg" href="#about-citigen">
            <span><FontAwesomeIcon icon={faArrowDown} size="1x" color="#286700"/></span>
            </Button>
            </Col>
        </Row>
    </Container>
    </div>
    </>
  );
}

export default LandingHeaderText;
