import React from "react";
// reactstrap components
import { Container, Col, Row } from "reactstrap";
// import "./landing.scss";

function AboutusHeaderText() {
  return (
    <>
    <div className="bg-gray-sm">  
    <Container>
        <Row>
            <Col sm="12" md="12" lg="12" className="mx-auto text-center">
            <h1 style={{color:"white",fontStyle:"normal",fontSize:"120px",fontFamily:"ProductSansRegular",fontWeight:"700"}} className="font-weight-bold">Who are we?</h1>
            <br/>
            <p style={{color:"white",fontWeight:"400",fontStyle:"normal",fontSize:"24px",fontFamily:"ProductSansRegular",lineHeight:"34.2px"}}>We are  group of Crazy, Stupid and Excited Individuals who are</p>
            <p style={{color:"white",fontWeight:"400",fontStyle:"normal",fontSize:"24px",fontFamily:"ProductSansRegular",lineHeight:"34.2px"}}>Managing Your Waste Magnificently</p>
            </Col>
        </Row>
    </Container>
    </div>
    </>
  );
}

export default AboutusHeaderText;